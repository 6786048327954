// This probably doesn't need to be it's own package. It doesn't do anything anymore.
// maybe if this had some fallback stuff where it could help with rendering parcels but as of right now I don't see the point.
//
// All this is a map with getters/setters
const slots = {
  'secondary-nav': '#secondary-nav',
  'page-header': '#page-header',
  'page-toolbar': '#page-toolbar',
  'page-sidebar': '#page-sidebar',
  'page-content': '#page-content',
}

export function getNamedNode(name) {
  const selector = slots[name]
  if (!selector) {
    return undefined
  } else {
    return document.querySelector(selector) || undefined
  }
}

// // expect a mapping of names to dom querySelectors
// export function createSlots(config = {}) {
//   Object.entries(config).forEach(([key, value]) => {
//     // TODO a better way to handle this than just throwing an error
//     if (slots[key]) {
//       const error = generateError(`Slot by name of ${key} already exists`)
//       throw error
//     }
//     slots[key] = value
//   })
// }

export function generateError(message) {
  const errorPrefix = `@wf-mfe/slots`
  return new Error(`${errorPrefix}: ${message}`)
}
