import reactDOM from 'react-dom'
import React from 'react'
// import Parcel from 'single-spa-react/parcel'
import { getNamedNode } from '../single-spa-slots/single-spa-slots.js'
// import { mountRootParcel } from 'single-spa'

export function Slot({ name, config = {}, children /*, ...other */ }) {
  const isParcel = !!config.bootstrap && !!config.mount && !!config.unmount
  const domNode = getNamedNode(name)
  if (isParcel) {
    console.error(`Parcel isn't working yet`)
    return null
    // return (
    //   <Parcel
    //     appendTo={domNode}
    //     // mountParcel={mountRootParcel}
    //     config={config}
    //     {...other}
    //   ></Parcel>
    // )
  } else {
    if (domNode) {
      return reactDOM.createPortal(children, domNode)
    } else {
      return null
    }
  }
}

function SlotExport(name, props) {
  const { children, ...other } = props
  return (
    <Slot {...other} name={name}>
      {children}
    </Slot>
  )
}

export const SecondaryNavSlot = SlotExport.bind(this, 'secondary-nav')
export const PageHeaderSlot = SlotExport.bind(this, 'page-header')
export const PageToolbarSlot = SlotExport.bind(this, 'page-toolbar')
export const PageSideBarSlot = SlotExport.bind(this, 'page-sidebar')
export const PageContentSlot = SlotExport.bind(this, 'page-content')
